@import "../../scss/variables";

#navbar {
  clear: both;
  position: fixed;
  top: 75px;
  width: 333px;
  bottom: 0;
  padding-bottom: 100px;
  background: #f3f3f3;
  border: 1px solid #bbb;
  border-left: 0;
  left: 1px;

  .menu-collapsed & {
    width: 88px;

    a.md-button {
      width: 88px;
      padding-right: 12px;
    }

    .collapse-button {
      left: 0px;
      width: 88px;
    }
  }

  p {
    font-size: 18px;
    line-height: 18px;
    text-transform: none;
    margin-top: 5px;
    margin-left: 10px;
    float: left;
    font-weight: 700;
  }

  i, ng-md-icon {
    font-weight: normal;
    font-style: normal;
    font-size: 42px; /* Preferred icon size */
    display: block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    color:#000;
    margin-top: 5px;
    margin-right: 10px;
    float: right;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }

  section {
    margin:-1px 0 0 -2px;
    padding:0;
  }

  a.md-button {
    font-size: 1.2em;
    line-height:60px;
    /* offset-x | offset-y | blur-radius | color */
    text-shadow:  1px 1px 1px rgba(255, 255, 255, .8), -1px -1px 1px rgba(0,0,0,.2);
    background: rgb(255,255,255); /* Old browsers */
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(214,214,214,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d6d6d6',GradientType=0 ); /* IE6-9 */
    width: 333px;
    margin: 0px 0 0 1px;
    padding-top: 15px;
    border: 0 solid transparent;
    height: 80px;
    border-radius:0;

    span {
      display: block;
      clear: left;
      margin-left: 25px;
      text-align: left;
      text-transform:none;
      font-size: 14px;
      line-height: 16px;
    }

    &.artist-glow{
      &:hover{
        box-shadow: inset 0px -5px 4px 0px $yellow;
        // svg fix fir text-shadow
        ng-md-icon {
          -webkit-filter: drop-shadow( 1px 1px 7px $yellow );
          filter: drop-shadow( 1px 1px 7px $yellow );
        }
      }
      &.active {
        ng-md-icon{
          -webkit-filter: drop-shadow( 1px 1px 7px $yellow );
          filter: drop-shadow( 1px 1px 7px $yellow ); 
        }
      }
    }

    &.list-glow {
      &:hover{
        text-shadow: 1px 1px 7px $orange;
        box-shadow: inset 0px -5px 4px 0px $orange;
      }
      &.active {
        i {
          text-shadow:  1px 1px 7px $orange;
        }
      }
    }

    &.email-glow {
      &:hover{
        text-shadow: 1px 1px 7px $turquoise;
        box-shadow: inset 0px -5px 4px 0px $turquoise;
      }
      &.active {
        i {
          text-shadow: 1px 1px 7px $turquoise;
        }
      }
    }

    &.application-glow {
      &:hover{
        text-shadow: 1px 1px 7px $purple;
        box-shadow: inset 0px -5px 4px 0px $purple;
      }
      &.active {
        i {
          text-shadow:  1px 1px 7px $purple;
        }
      }
    }

    &.payments-glow {
      // padding: 15px 15px 0 6px;
      &:hover{
        text-shadow: 1px 1px 7px $green;
        box-shadow: inset 0px -5px 4px 0px $green;
      }
      &.active {
        i {
          text-shadow: 1px 1px 7px $green;
        }
      }
    }

    &.admin-glow{
      &:hover{
        text-shadow: 1px 1px 7px $blue-dark;
        box-shadow: inset 0px -5px 4px 0px $blue-dark;
      }
      &.active {
        i {
          text-shadow: 1px 1px 7px $blue-dark;
        }
      }
    }

    &.logout-glow{
      &:hover{
        box-shadow: inset 0px -5px 4px 0px $black;
        // svg fix fir text-shadow
        ng-md-icon { 
          -webkit-filter: drop-shadow( 1px 1px 5px $black );
          filter: drop-shadow( 1px 1px 5px $black );
        }
      }
      &.active {
        ng-md-icon {
          -webkit-filter: drop-shadow( 1px 1px 5px $black );
          filter: drop-shadow( 1px 1px 5px $black );
        }
      }
    }

    &.active:hover {
      box-shadow: none;
    }
  }

  .logout-button {
    position: fixed;
    bottom: 78px;
    left: -1px;
    line-height: 50px;
    p {
      margin-top: 20px;
    }
  }

  .collapse-button {
    position: fixed;
    bottom: 28px;
    left: 0;
    height: 50px;
    margin: 0;
    background-color: $grey-very-light;
    width: 333px;

    ng-md-icon {
      margin: 0 16px 0 0;
    }
  }
}

#navbar .md-button:hover{
  color:#000;

}
#navbar a.md-button:hover i, #navbar a.md-button:hover ng-md-icon{
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
// Rainbow
#navbar .md-button.rainbow-glow:hover i, #navbar .md-button.active.rainbow-glow i{

  text-shadow:none;
  background: url('../assets/images/artist-bg.png') no-repeat;
  background-size: 42px 42px;
}

#navbar .md-button.active.rainbow-glow i{
  opacity:0.9;
}

#navbar .md-button.active, #navbar .md-button.active:hover{
  background: #fff;
  border:1px solid #bbb;
  border-right:0px;
  border-left:0px;
  border-radius: 0px;
  margin-left:0px;
  margin-top:-1px;
  box-shadow: none;
  cursor:default;
  z-index:25;
}
#navbar .md-button.active:hover i, #navbar .md-button.active:hover ng-md-icon {
  animation:not;
  transform: scale(1);
}
#navbar .md-button.active:hover p, #navbar .md-button:hover p{
  text-shadow:1px 1px 1px rgba(255, 255, 255, .8), -1px -1px 1px rgba(0,0,0,.2);
}

#navbar .md-button.active:hover span, #navbar .md-button:hover span {
  text-shadow:1px 1px 1px rgba(255, 255, 255, .8), -1px -1px 1px rgba(0,0,0,.2);
}

#navbar .md-button:hover i, #navbar .md-button:hover ng-md-icon {
  transform: scale(1.2);
  animation: stretch .3s linear;
}

@keyframes stretch {
  0% {
    transform: scale(1);

  }

  50% {
    transform: scale(1.1);

  }

  100% {
    transform: scale(1.2);

  }
}


