.job-capacity {
  text-align: center;
  .header {
    padding: 14px 0;
    & span {
      font-size: 22px;
      color: #fff;
    }

    ng-md-icon {
      &[icon="edit"] { color: #fff; }
    }
  }

  .repair {
    .header {
      background-color: red;
    }
  }

  .select {
    .header {
      background-color: orange;
    }
  }

  .design {
    .header {
      background-color: purple;
    }
  }

  .edit-form-inline-buttons{
    display: inline-block;
  }

  .capacity-table {
    tr td {
      vertical-align: middle;
    }
    md-input-container {
      margin: 0;
    }
    .md-select-value {
      & span {
        min-width: 100px;
      }
    }
  }

}

.pull-r {
  float: right;
}

.bonuses {
  max-width: 500px;

  md-input-container {
    margin-top: 0;
  }
}