.lists {

  .lists-table {
    margin: 0;
    width: 100%;
    border: 1px solid #c0c0c0;
    border-bottom:none;

    thead {
      tr {
        height: 40px;

        th {
          text-align: center;
          border-right: 1px solid #c0c0c0;
          border-bottom:1px solid #c0c0c0;
          &.header {
            position: relative;
            background: none;
            height: auto;
            left: auto;
            right: auto;
            top: auto;
            z-index: auto;
            min-width: inherit;
            box-shadow: none;
          }
        }
      }
    }
    td {
      padding: 4px 2px;
      line-height: 1.5em;
    }
  }


  md-dialog-actions {
    display: flex;
    order: 2;
    justify-content: flex-end;
  }

  .form-inline {
    padding: 10px 10px 10px 0px;
    text-align: center;

    .editable-wrap {
      margin-left: 15px;
    }

    input[type="text"] {
      padding: 4px;
    }
  }

  .listSelected {
    background-color: $grey-hl;
  }

  .exportBtn {
    padding: 0px;
    background-color: #000000 !important;
    color: #ffffff !important;
    margin: 10px 0 0 0;
    i {
      font-size: 2.8em;
      line-height: 2em;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      line-height: 40px;
      vertical-align: top;
      margin-top: 20px;
    }
  }

  &-table-filter{
    width:100%;
  }

  .btn-checkmark{
    background:#ff9b00;
    border-color: #e1a453;

    &:hover,
    &:active{
      background:#ff9b00;
      opacity:.75;
      border-color: #e1a453;
    }
  }

}

.btn-save{
  background:#ff9b00;
  border-color: #e1a453;

  &:hover,
  &:active{
    background:#ff9b00;
    opacity:.75;
    border-color: #e1a453;
  }
}

.custom-list-header{
  margin-bottom:-83px;
}

.lists-add-table-filter{
  max-width:200px;
}

.lists-table{
   md-checkbox{
      margin:10px 0 0 0;
      line-height:1;
      min-height:100%;
      
      .md-container{
        top:9px;
      }
    }
}

.scroll.list-scroll{
  margin-bottom:0;
}

.btn-download {
  margin-top: 10px;
  border: 1px solid #CCCCCC;
  -webkit-box-shadow: #FEFFFF 1px 1px 1px;
  -moz-box-shadow: #FEFFFF 1px 1px 1px;
  box-shadow: #FEFFFF 1px 1px 1px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px;
  line-height: 25px;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  font-weight: bold;
  color: #000;
  background-color: #FFFFFF;
  background-image: linear-gradient(to bottom, #FFFFFF, #CCCCCC 75%);

  &:hover {
    color: #000;
    background-image: linear-gradient(to top, #FFFFFF, #CCCCCC 75%);
  }
}