
$nav-shadow: 0px 0px 6px -2px rgba(0,0,0,0.65);
$grey: #999999;
$grey-hl: #cccccc;
$grey-ll: #666666;
$grey-very-light: #DBDBDB;
$red: #ff0000;
$red-hl: #ff6666;
$red-ll: #cc0000;
$orange: #ff9900;
$orange-hl: #ffcc66;
$orange-ll: #ff6600;
$purple: #990099;
$purple-hl: #CC92CC;
$purple-ll: #330066;
$pink: #ff33cc;
$pink-hl: #ff66cc;
$pink-ll: #cc0099;
$blue:#0066FF;
$blue-dark: #0000ff;
$green:#339900;
$green-hl:#33cc00;
$lime:#00FF00;
$yellow:#FFFF00;
$teal:#00ffff;
$turquoise: #339999;
$black:  #000000;