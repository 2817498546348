.md-button, .btn {
  color: $black;

  &.cta {
    &-primary {
      background-color: orange;
    }

    &-success {
      background-color: green;
    }

    &-secondary {
      background-color: lighten(grey, 30%);
    }
  }

  &.btn-orange {
    @include button(#e1a453, linear-gradient(to bottom, #ffc87e, #ff9b00),
    linear-gradient(to bottom, #fec87e, #fb7d05), #fec87e);

    &.btn-user-profile {
      font-size:1.1em;
      padding: 6px 12px;
    }

    &.btn-note {
      margin: 0 8px;
      padding: 0 12px;
    }

  }

  &.btn-green{
    @include button(#066406, linear-gradient(to bottom, #57ac57, #008f02),
    linear-gradient(to bottom, #54ac56, #09c60a), #52ac52);
    font-size:1em;
    color:#fff;
  }

  &.btn-white{
    @include button(#d5d5d5, linear-gradient(to bottom, #fefefe, #ebebeb),
    linear-gradient(to bottom, #fefefe 70%, #f2b564), #f5f5f5);

    &.btn-user-profile {
      font-size:1.1em;
      padding: 6px 12px;
    }
  }

  &.btn-questionnaire{
    position: absolute;
    right: 0;
    top: -20px;
    font-size: 0.875em;
    line-height: 1em;
  }

  &.btn-login{
    @include button(#e1a453, linear-gradient(to bottom, #ffc87e, #ff9b00),
    linear-gradient(to bottom, #fec87e, #fb7d05), #fec87e);
    font-size:1em;
    padding:8px 0;
    border-radius:6px;
    min-height:30px;
    line-height:1;

    .md-ripple-container{
      border-radius:4px;
    }
  }

  &.btn-fullwidth{
    width:100%;
  }

  &.btn-export{
    margin:15px 0;
    min-height:40px;
    line-height:40px;
  }
}
