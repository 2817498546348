.bmd {
  max-width: 100%;

  th {
    color: #ffffff;
    font-weight: 700;
    font-size: 1.25em;
    text-align: center;
  }

  td {
    text-align: center;
  }

  .buttons {
    display: inline;
  }

  &-repair {
    th {
      background-color: $red;
    }
  }

  &-select {
    th {
      background-color: $orange;
    }
  }

  &-design {
    th {
      background-color: $purple;
    }
  }

  &-form {
    width: 30%;
    max-width: 400px;
  }

  md-input-container .md-errors-spacer {
    min-height: 0;
  }
}

.bonusBadgeBmd {
  color: black;
  font-size: 12px;
  img {
    width:19px;
    transform: translateY(-1px);
  }
}
