.ics-table {

  .ics-table-content {
    a {
      color: $blue;

      &.btn-download {
        margin-top: 10px;
        border: 1px solid #CCCCCC;
        -webkit-box-shadow: #FEFFFF 1px 1px 1px;
        -moz-box-shadow: #FEFFFF 1px 1px 1px;
        box-shadow: #FEFFFF 1px 1px 1px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 5px;
        font-size: 14px;
        padding: 5px;
        line-height: 25px;
        text-decoration: none;
        text-transform: uppercase;
        display: inline-block;
        font-weight: bold;
        color: #000;
        background-color: #FFFFFF;
        background-image: linear-gradient(to bottom, #FFFFFF, #CCCCCC 75%);
    
        &:hover {
          background-image: linear-gradient(to top, #FFFFFF, #CCCCCC 75%);
        }
      }
    }
  }

  &.__reports {

    &-period {
      thead {
        th {
          background: #000;
          color: #fff;
        }
      }

      // tbody {
      //   tr:nth-child(odd) {
      //     background-color: lighten(#000, 85%);
      //   }
      // }
    }

    &-repair {
      background-color: #fff;

      tbody {
        tr:nth-child(odd) {
          background-color: lighten($red, 45%);
        }

        .disabled,
        .disabled span {
          color: grey !important;
          pointer-events: none;
        }
      }

      thead, tfoot {
        th, td {
          background: $red;
          font-weight: 700;
          color: #fff;
        }
      }

      .bonus {
        span {
          color: $orange;
          font-weight: 700;
        }

        &-elite {
          span {
            color: $blue;
            font-weight: 700;
            font-style: italic;
          }
        }

        &-bmd {
          color: $green;
          font-weight: 700;
        }
      }
    }

    &-select {
      background-color: #fff;

      tbody {
        tr:nth-child(odd) {
          background-color: lighten($orange, 45%);
        }
      }

      thead, tfoot {
        th, td {
          background: $orange;
          font-weight: 700;
          color: #fff;
        }
      }

      .bonus {
        span {
          color: $orange;
          font-weight: 700;
        }

        &-bmd {
          color: $green;
          font-weight: 700;
        }
      }
    }

    &-design {
      background-color: #fff;

      tbody {
        tr:nth-child(odd) {
          background-color: lighten($purple, 66%);
        }
      }

      thead, tfoot {
        th, td {
          background: $purple;
          font-weight: 700;
          color: #fff;
        }
      }

      .bonus {
        span {
          color: $orange;
          font-weight: 700;
        }

        &-bmd {
          color: $green;
          font-weight: 700;
        }
      }
    }
  }

  &.__reports-total{
    td{
      padding:0;

      .reports-total{
        background-color: #000;
        color: #FFFFFF;
        font-weight: bold;
        padding: 5px 10px;

        .push-right {
          float: right;
        }
      }
    }
  }
  

  table{
    margin-bottom:0;
  }

  .btn-orange{
    float:right;
  }
}

.ics-table.__reports-period .__reports-period-artist > thead th{
  background:#eee;
  color:#000;
}

.ics-table.__reports-period > thead th:nth-child(4){
  background:#ff2700;
  color:#fff;
}

.ics-table.__reports-period > thead th:nth-child(5){
  background:#fe9402;
  color:#fff;
}

.ics-table.__reports-period > thead th:nth-child(6){
  background:#aa27ab;
  color:#fff;
}

.ics-table.__reports-period > thead th:nth-child(7){
  background:#fffb04;
  color:#000;
}

.ng-table {
  th.sortable {
      &.sort-desc, &.sort-asc {
        text-shadow: none;
        background: #000;
        color: #fff;
      }
  }
}

.paymentMaxHeight {
  max-height: 900px;
}

.paymentMaxHeightContainer {
  max-height: 85vh;
}

.payments {
  .ng-table-pager {
    display: block;
  }

  .ng-table-pagination {
    margin: 0;
    float: right;
    margin-right: 10px;
  }

  .ng-table-counts {
    display: none;
  }
}

.referred-artist {
  cursor: pointer;
  outline: none;
  vertical-align: middle;

  i {
    position: relative;
    top: 3px;
  }

  &__table {
    background: white;
  }
}

.bonusBadgePayReport {
  color: black;
  margin-left: 10px;
  img {
    width:28px;
    -webkit-transform: translateY(-1px);
    -moz-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    -o-transform: translateY(-1px);
    transform: translateY(-1px);
  }
}
