.role {
  &-permissions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__tag {
      border-radius: 20px;
      background-color: $grey-hl;
      margin: 5px;
      padding: 5px 10px;

      .fa {
        font-size: 18px;
        color: $red;
      }
    }
  }

  &-highlight {
    padding: 5px 10px;
    background-color: #efefef;
    margin-top: 10px;
  }
}

.roles-container {
  padding-bottom: 0;

  md-checkbox {
    line-height: 2;
    margin-left: 0;
  }
}
