@mixin scrollbars($thumb-color) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width:  0.65em;
    height:  0.65em;
  }

  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
    // border-radius: 10px;
    padding: 2px;
  }

  &::-webkit-scrollbar-track {
    background: $grey;
  }
}

.scroll {
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 10px;

  &--main {
    @include scrollbars($orange);
  }

  &--list {
    @include scrollbars($orange);
  }

  &--aplication {
    @include scrollbars($orange);
    padding-bottom: 120px;
  }

  &--payment {
    @include scrollbars($orange);
  }

  &--email {
    @include scrollbars($orange);
  }

  &--admin {
    @include scrollbars($orange);
  }
}
